import React from "react";
import styled from "styled-components";
import { colors, transitions, device } from "../Layout/theme";
import WorldsIcon from "../../images/logo/logo-worlds-23-white.png";

interface Params {
  className?: string;
}

const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
`;

const Loader = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  svg {
    position: relative;

    path {
      fill: ${colors.blue};
    }

    &:nth-child(2) {
      animation: diagonal-wipe 1.4s linear infinite;
      left: 0;
      position: absolute;
      top: 0;

      path {
        fill: lightgrey;
      }
    }
  }
`;

const IconWrapper = styled.div`
  overflow: hidden;

  img {
    width: 40px;
    position: relative;
    filter: drop-shadow(0px 500px 0 #e0c27d);
    transform: translateY(-500px);
  }

  &:nth-child(2) {
    position: absolute;
    left: 0;
    top: 0;
    animation: diagonal-wipe 1s linear infinite;

    img {
      filter: drop-shadow(0px 500px 0 ${colors.white});
    }
  }

  @keyframes diagonal-wipe {
    0% {
      clip-path: polygon(-25px 25px, 25px 75px, -26px 127px, -77px 76px);
    }

    100% {
      clip-path: polygon(76px -77px, 127px -26px, 76px 26px, 25px -26px);
    }
  }
`;

const Loading: React.FC<Params> = ({ className }) => {
  return (
    <LoaderWrapper className={className} data-testid="loading">
      <Loader>
        <IconWrapper>
          <img src="/logo-worlds-23-short-white.png" />
        </IconWrapper>
        <IconWrapper>
          <img src="/logo-worlds-23-short-white.png" />
        </IconWrapper>
      </Loader>
    </LoaderWrapper>
  );
};

export default Loading;
