import React from "react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql, navigate } from "gatsby";
import Layout from "../components/Layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LoginPurchase from "../components/LoginPurchase";

const postLoginPurchase: React.FC = () => {
  const { t } = useTranslation("translations");
  const { language } = useI18next();

  return (
    <>
      <Layout>
        <GatsbySeo title="Redirecting" noindex={true} />
        <LoginPurchase />
      </Layout>
    </>
  );
};

export default postLoginPurchase;

export const query = graphql`
  query ($language: String!) {
    ...TranslationQueryFragment
  }
`;
