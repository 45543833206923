import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "../../store/index";
import useStore from "../../context/StoreContext";
import Loading from "../Loading";

const PageWrapper = styled.div`
  display: grid;
  place-items: center;
  min-height: 80vh;
`;

const ContentWrapper = styled.div`
  width: 84vw;
  max-width: 500px;
  padding-bottom: 15vh;
`;

const Title = styled.p`
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem !important;
  font-weight: 500;
  padding-bottom: 1rem;
`;

const isProductTypeValid = (productType: any) => {
  if (productType == "digital" || productType == "collector") {
    return true;
  }
  return false;
};

const isIsEuropeValid = (isEurope: any) => {
  if (isEurope != null && (isEurope == "true" || isEurope == "false")) {
    return true;
  }
  return false;
};

const LoginPurchase: React.FC = ({}) => {
  const { language } = useI18next();

  const isUserAuthenticated = useAppSelector((state) => state.user.isAuthenticated); // from rso login or Riotbar function
  const isRiotbarLoaded = useAppSelector((state) => state.user.isRiotbarLoaded);

  useEffect(() => {
    if (isRiotbarLoaded) {
      if (!isUserAuthenticated) {
        navigate(`/${language}/404`);
      }
    }
  }, [isRiotbarLoaded]);

  // checkout logic
  const dispatch = useAppDispatch();
  const { addToCartByProductTypeAndRegion } = useStore();

  useEffect(() => {
    if (!isRiotbarLoaded) {
      return;
    }

    // is user is not logged in & access the link by chance, we should block it from going to the checkout as not signen in yet
    // DEBUG: can comment out and see if the checkout link is working properly
    if (!isUserAuthenticated) {
      navigate(`/${language}/404`);
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const hasLogin = urlParams.get("hasLogin");
    const productType = urlParams.get("productType");
    const isEurope = urlParams.get("isEurope");

    const handleCheckout = async (productType: string, isEurope: boolean) => {
      try {
        const checkoutURL = await addToCartByProductTypeAndRegion(productType, isEurope);

        if (checkoutURL) {
          const localizedCheckoutURL = `${checkoutURL}&locale=${language}`;

          // go to returned checkoutURL directly
          window.location.href = localizedCheckoutURL;
        } else {
          // shopify connection error / out of stock so cannot add to cart, when direct to homepage will see "sold out"
          navigate(`/${language}/api-connection-error`);
        }
      } catch (err) {
        // shopify connection error
        navigate(`/${language}/api-connection-error`);
        console.log(err);
      }
    };

    if (productType == null || isEurope == null || hasLogin == null) {
      navigate(`/${language}/404`);
    } else if (hasLogin == "true" && isProductTypeValid(productType) && isIsEuropeValid(isEurope)) {
      // valid
      // console.log(hasLogin, productType, isEurope);
      const typedIsEurope = JSON.parse(isEurope);
      handleCheckout(productType, typedIsEurope);
    }
  }, [isRiotbarLoaded]);

  return (
    <PageWrapper>
      <ContentWrapper>
        <Loading />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default LoginPurchase;
